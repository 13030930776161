<template>
    <div class="">
        <div class="row">
            <div class="col-md-2">
                <label class="form-label">Çalışma Durumu</label>
                <select class="form-control" v-model="filterData.OfferStatus">
                    <option :value="null">Tümü</option>
                    <option :value="0">Taşımacıya gönderildi. Fiyat çalışılması bekleniyor.</option>
                    <option :value="1">Taşımacı tarafından yanıtlandı. Fiyat kontrolü yapılması bekleniyor.</option>
                    <option :value="2">Teklif, teklif oluşturan kişi tarafından iptal edildi.</option>
                    <option :value="3">Teklif süreci başarıyla tamamlandı. Taşımacının sözleşmeye dönüştürme onayı bekleniyor.</option>
                    <option :value="4">Sözleşmeye dönüştürüldü.</option>
                </select>
            </div>
            <div class="col-auto mt-2">
                <button class="btn btn-secondary" v-on:click="$emit('filter',filterData)">Filtrele</button>
            </div>
            <div class="col-auto mt-2">
                <button class="btn btn-dark" v-on:click="clearForm">Temizle</button>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data(){
        return{
            filterData:{
                OfferStatus:null
            }
        }
    },
    methods:{
        clearForm(){
            this.filterData.OfferStatus = null
            this.$emit('filter',this.filterData)
        }
    }
}
</script>