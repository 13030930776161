<template>
    <b-card no-body>
        <v-table    ref="table"
                    :add-callback="createNew"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server">
                        <template #filters>
                            <offer-filter v-on:filter="filter"></offer-filter>
                        </template>
                </v-table>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { OfferApi } from '@/api/offer'
import OfferFilter from './components/OfferFilter.vue'
export default {
    components:{
        OfferFilter,
        BCard
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Cari Kod',key:'CariCode',sortable:true, tdClass:'text-nowrap', thClass:'w-10'},
                {name:'Cari',key:'CariName',sortable:true,tdClass:'text-nowrap',thClass:'w-20'},
                {name:'Teklif Kodu',key:'OfferCode',sortable:true,tdClass:'text-nowrap',thClass:'w-15'},
                {name:'E-Posta',key:'Email',sortable:false,tdClass:'text-nowrap',thClass:'w-15'},
                {name:'Durum',key:'StatusName',sortable:true,sortKey:'OfferStatus',tdClass:'text-nowrap',thClass:'w-5'},
                {name:'Teklif Son Yanıtlama Tarihi',tdClass:'text-nowrap',key:'OfferLastAnswerDate',sortKey:'LastAnswerDate',sortable:true,thClass:'w-15'},
                {name:'Sözleşme Başlangıç Tarihi',tdClass:'text-nowrap',key:'OfferAgreementStartDate',sortKey:'AgreementStartDate',sortable:true,thClass:'w-10'},
                {name:'Sözleşme Bitiş Tarihi',key:'OfferAgreementEndDate',sortable:true,tdClass:'text-nowrap',sortKey:'AgreementEndDate',thClass:'w-10'},
            ],
            buttons:[
                { text:"Düzenle",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.edit }
            ],
            filterData:{
				OfferStatus:null
			},
        }
    },
    methods:{
        provider(data){
            return OfferApi.Table({PaginationRequestModel:data,...this.filterData})
        },
        createNew(){
            this.$router.push({ name: 'offer-request-create' })
        },
        edit(item){
            this.$router.push({name:'offer-request-update',params:{id:item.Id}})
        },
        filter(filter){
            this.filterData = filter
            this.$refs.table.refresh()
        },
    }
}
</script>

<style>

</style>